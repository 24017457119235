
// import $ from 'jquery';
// import('slick-slider');

export function tabs(){
    

    let descriptionButon = document.querySelector('.sect-karusel-k-bottom-tabs-description-tab');
    let reviewsButon = document.querySelector('.sect-karusel-k-bottom-tabs-reviews-tab');

    let descriptionButonContent = document.querySelector('.sect-karusel-k-bottom-content-description-tab');
    let reviewsButonContent = document.querySelector('.sect-karusel-k-bottom-content-reviews-tab');
        if(descriptionButon && reviewsButon && descriptionButonContent && reviewsButonContent){
            console.log(descriptionButon);
            descriptionButon.addEventListener('click', ()=>{
                
                descriptionButon.classList.add('active');
                reviewsButon.classList.remove('active');

                descriptionButonContent.classList.add('active');
                reviewsButonContent.classList.remove('active');
            })

            reviewsButon.addEventListener('click', ()=>{
                descriptionButon.classList.remove('active');
                reviewsButon.classList.add('active');

                descriptionButonContent.classList.remove('active');
                reviewsButonContent.classList.add('active');
                


                let sectSeeAlsoNext = document.querySelector('.sect-see-also-next');
            if(sectSeeAlsoNext){
              console.log(21);
              sectSeeAlsoNext.click();
              console.log('1sl');
            }

             let sectSeeAlsoNextto = document.querySelectorAll('.sect-see-also-next')[0];
                if(sectSeeAlsoNextto){
                sectSeeAlsoNextto.click();
                console.log('2sl');
                }



            })
        }
    


}






// class Rectangle {
//     constructor(heigth, width){
//         this.heigth = heigth;
//         this.width = width;
//     }

//     calcArea(){
//         return this.heigth + this.width;
//     }
// }

// class ColorRedRectangleWithText extends Rectangle{
//     constructor(heigth, width, text, bcColor){
//         super(heigth, width);
//         this.text = text;
//         this.bcColor = bcColor;
//     }

//     showMyProps(){
//         console.log(`Цвет:${this.bcColor}, Текст: ${this.text}`);

//     }
// }

// let svoistva =  new ColorRedRectangleWithText(10, 20, 'ТТТТ', 'redd');
// svoistva.showMyProps();
// console.log(svoistva.calcArea());

// const kvadrat = new Rectangle(12, 20);

// console.log(kvadrat.calcArea());



// function sozdObg( arg1, arg2, arg3 ){
//     this.arg1 = arg1;
//     this.arg2 = arg2;
//     this.arg3 = arg3; 
// }

// let newobg =  new sozdObg(10, 20, 23);
// console.log(newobg.arg3);


// let sozdObg = {
//     '41':2,
//     idd:3,
//     iddd:5,
// }
// console.log(sozdObg.41);


// let str = 'some';
// let strObg = new String(str);
// let strObgto = strObg;

// console.log(typeof(str));
// console.log(typeof(strObg));
// console.log(typeof(strObgto));




// let ans = 'Nmymnhxphymynp';
// const reg=/n/g
// console.log(ans.search(reg));

// let ans = 'mymhxphymynp';
// const reg=/mhxphymyn/ig
// console.log(ans.replace(reg, ''));


// function consUser(name, age){
//     this.name = name;
//     let = userAge = age;

//     this.say = function(){
//         console.log(`Имя:${this.name}, возвраст: ${userAge}`)
//     }
//     this.getAge = function(){
//         return userAge;
//     }
//     this.setAge = function(age){
//         if(typeof age === 'number' && age > 0 && age < 110 ){
//             userAge = age;
//         } else{
//             console.log('недопуст');
//         }
//     }
// }

// let ivan = new consUser('ivan', 27);
// ivan.setAge(44)
// console.log(ivan.getAge());

//     ivan.say()