export function forms(){
    let popupFormClose = document.querySelector('.popup-form-close');
    let popupFormFon = document.querySelector('.popup-form-fon');
    let popupFormFonInfo = document.querySelector('.popup-form-fon-info');
    if(popupFormClose && popupFormFon && popupFormFonInfo){
        popupFormClose.addEventListener('click', popupFormCloseF);
        function popupFormCloseF(){
            popupFormFon.classList.toggle('popup-form-fon-none');
        }

        let catalogProduktsJs = document.querySelectorAll('.catalog-produkts-js .order');
        if(catalogProduktsJs.length>0){
            catalogProduktsJs.forEach(element => {
                element.addEventListener('click', function(){
                    let topElementCatalogProdukt = this.closest('.catalog-produkt');
                    let text = topElementCatalogProdukt.querySelector('.catalog-produkt-btm-top').textContent;
                    popupFormFonInfo.value = text;
                    popupFormFon.classList.toggle('popup-form-fon-none');
                })
            });
        }


        let catalogProduktsJsorder = document.querySelectorAll('.slider .order');
        if(catalogProduktsJsorder.length>0){
            catalogProduktsJsorder.forEach(element => {
                element.addEventListener('click', function(){
                    let topElementCatalogProdukt = this.closest('.catalog-produkt');
                    let text = topElementCatalogProdukt.querySelector('.catalog-produkt-btm-top').textContent;
                    popupFormFonInfo.value = text;
                    popupFormFon.classList.toggle('popup-form-fon-none');
                })
            });
        }
        
        popupFormFon.addEventListener('click', function(e){
            console.log(e.target);
            if((e.target.querySelector('.popup-form'))){
                popupFormFon.classList.toggle('popup-form-fon-none');
            }
        });
    }
    
    let sectionCancelTheApplicationF = document.querySelector('.section-cancel-the-application-f');
    if(sectionCancelTheApplicationF){
        sectionCancelTheApplicationF.addEventListener('click', ()=>{
            popupFormFonInfo.value = 'Заявка с кнопкки Оставить заявку, которая возле подвала сайта';
            popupFormFon.classList.toggle('popup-form-fon-none');
        })
    }
    

    let callPopupFormBotton = document.querySelector('.callPopupForm-botton');
    if(callPopupFormBotton){
        callPopupFormBotton.addEventListener('click', ()=>{
            popupFormFonInfo.value = 'Заявка с кнопкки Оставить заявку, которая под услугами на сайте';
            popupFormFon.classList.toggle('popup-form-fon-none');
        })
    }
   

    let sectKaruselKTopOrder = document.querySelector('.sect-karusel-k-top-order');
    if(sectKaruselKTopOrder){
        sectKaruselKTopOrder.addEventListener('click', ()=>{
            popupFormFonInfo.value = 'Заявка с кнопкки Оставить заявку, которая на странице услуги';
            popupFormFon.classList.toggle('popup-form-fon-none');
        })
    }
    

    //отправка на сервер

        // Forms

        const forms = document.querySelectorAll('form');
        const message = {
            loading: 'Загрузка...',
            success: 'Спасибо! Скоро мы с вами свяжемся',
            failure: 'Что-то пошло не так...'
        };
    
        forms.forEach(item => {
            postData(item);
        });
    
        function postData(form) {
            form.addEventListener('submit', (e) => {
                e.preventDefault();
    
                // let statusMessage = document.createElement('div');
                // statusMessage.classList.add('status');
    
                let statusMessage = form.querySelector('.status');
    
                statusMessage.textContent = message.loading;
                statusMessage.classList.remove('status_true');
                statusMessage.classList.remove('status_false');
                statusMessage.classList.add('status_zagr');
                //form.appendChild(statusMessage);
            
    
    
    
    
                // const request = new XMLHttpRequest();
                // request.open('POST', '/wp-content/themes/avtozapt/ajax/forms.php');
                // request.setRequestHeader('Content-type', 'application/json; charset=utf-8');
                const formData = new FormData(form);
                //console.log(formData);
                const object = {};
                formData.forEach(function(value, key){
                    object[key] = value;
                });
    
                const json = JSON.stringify(object);
                //console.log(json);
                // request.send(json);
    
                // request.addEventListener('load', () => {
                //     if (request.status === 200) {
                //         console.log(request.response);
                //         statusMessage.textContent = message.success;
    
                //         statusMessage.classList.add('status_true');
                //         statusMessage.classList.remove('status_false');
                //         statusMessage.classList.remove('status_zagr');
    
                //         form.reset();
                //         setTimeout(() => {
                //             statusMessage.classList.remove('status_true');
                //             statusMessage.classList.remove('status_false');
                //             statusMessage.classList.remove('status_zagr');
                //         }, 3000);
                //     } else {
                //         statusMessage.textContent = message.failure;
    
                //         statusMessage.classList.remove('status_true');
                //         statusMessage.classList.add('status_false');
                //         statusMessage.classList.remove('status_zagr');
                //     }
                // });
    
                //
    
                // Отправить POST-запрос
                axios({
                  method: 'post',
                  url: '/wp-content/themes/avtozapt/ajax/forms.php',
                  data: json
                })
                .then(function (response) {
                    console.log(response);
                    if(response.data == 'true'){
                        statusMessage.textContent = message.success;
    
                        statusMessage.classList.add('status_true');
                        statusMessage.classList.remove('status_false');
                        statusMessage.classList.remove('status_zagr');
    
                        form.reset();
                        setTimeout(() => {
                            statusMessage.classList.remove('status_true');
                            statusMessage.classList.remove('status_false');
                            statusMessage.classList.remove('status_zagr');
                        }, 3000);
                    }
                    else{
                        console.log(error);
                        statusMessage.textContent = message.failure;
        
                        statusMessage.classList.remove('status_true');
                        statusMessage.classList.add('status_false');
                        statusMessage.classList.remove('status_zagr');
                    }
                       
    
                       
                  })
                  .catch(function (error) {
    
                     console.log(error);
                    statusMessage.textContent = message.failure;
    
                    statusMessage.classList.remove('status_true');
                    statusMessage.classList.add('status_false');
                    statusMessage.classList.remove('status_zagr');
                  });
                //
            });
        }
    
}