export function karuselNavNext(){
    let karuselNavElementsContent = document.querySelector('.karusel-nav-elements-content');
    if(karuselNavElementsContent){
                

                let baborDeistviyLength = document.querySelectorAll('.karusel-nav-elements .element');
                let karuselNavElements = document.querySelector('.karusel-nav-elements');
                
                
                if(baborDeistviyLength.length<5){
                    document.querySelector('.karusel-nav-prev').classList.add('disNone');
                    document.querySelector('.karusel-nav-next').classList.add('disNone');
                }
                if(baborDeistviyLength.length>4){
                    karuselNavElements.classList.add('karuselNavElements_big_4');
                }

                let karuselNav = document.querySelector('.karusel-nav');
                karuselNav.style.height=karuselNav.scrollHeight + 'px';

                
                if(baborDeistviyLength.length<4){
                    baborDeistviyLength[0].classList.add('active');
                    let karuselNavElementsElements = document.querySelectorAll('.karusel-nav-elements .element');
                    karuselNavElementsElements.forEach((element, index, arr) => {
                        element.style.height=Math.ceil((karuselNavElements.clientHeight / 3)) + 'px'; 
                    });


                    let elementMinFor = document.querySelectorAll('.element-min-for');
                    elementMinFor.forEach(element => {
                        element.addEventListener('click', function(e){
                            document.querySelector('.sect-karusel-okno-img img').src=e.target.getAttribute('data-img-karusel');
                        });
                    });


                }
                if(baborDeistviyLength.length==4){
                    baborDeistviyLength[0].classList.add('active');
                    let karuselNavElementsElements = document.querySelectorAll('.karusel-nav-elements .element');
                    karuselNavElementsElements.forEach((element, index, arr) => {
                        element.style.height=Math.ceil((karuselNavElements.clientHeight / 4)) + 'px'; 
                    });

                    let elementMinFor = document.querySelectorAll('.element-min-for');
                    elementMinFor.forEach(element => {
                        element.addEventListener('click', function(e){
                            document.querySelector('.sect-karusel-okno-img img').src=e.target.getAttribute('data-img-karusel');
                        });
                    });
                }
                if(baborDeistviyLength.length>4){
                    baborDeistviyLength[2].classList.add('active');
                    karuselNavElementsContent.style.top= '-' + Math.ceil((karuselNavElements.clientHeight * (0.6666666666666) )) + 'px';
                    let karuselNavElementsElements;
                    setInterval(()=>{
                        
                        karuselNavElementsElements='';
                        karuselNavElementsElements = document.querySelectorAll('.karusel-nav-elements .element');
                        
                
                        karuselNavElementsElements.forEach((element, index, arr) => {
                            element.removeEventListener('click', elementClic);
                        });
                
                
                        karuselNavElementsElements.forEach((element, index, arr) => {
                            element.style.height=Math.ceil((karuselNavElements.clientHeight / 3)) + 'px';
                                element.addEventListener('click', elementClic);      
                            
                        });
                
                    }, 1000);
                
                    
                
                    function elementClic(e){
                        
                        let elements = '';
                        elements = document.querySelectorAll('.karusel-nav-elements .element');
                        let i=0;
                        elements.forEach(element => {
                            //console.log(i);
                            if (element == e.currentTarget){
                                if(i==3){
                                    document.querySelector('.karusel-nav-prev').click();
                                }
                                if(i==4){
                                    karuselNavPrevTo(e);
                                }
                            }
                            i = i + 1;
                        });
                    }
                
                
                
                
                
                
                    
                    let karuselNavNext = document.querySelector('.karusel-nav-next');
                    let karuselNavNextTrue = true;
                    karuselNavNext.addEventListener('click', ()=>{
                        if(karuselNavNextTrue == true){
                            karuselNavNextTrue = false;
                
                        
                
                
                
                            let karuselNavElements = document.querySelector('.karusel-nav-elements');
                            let karuselNavElementsContent = document.querySelector('.karusel-nav-elements-content');
                    
                            let lastElDelete = document.querySelector('.karusel-nav-elements .element:last-child');
                            let lastElDeleteHtml = lastElDelete.outerHTML;
                        
                            
                            let vewTop = karuselNavElementsContent.offsetTop + Math.ceil((karuselNavElements.clientHeight / 3));
                            karuselNavElementsContent.style.top = vewTop + 'px';
                            
                            setTimeout(()=>{
                                
                                karuselNavElements.classList.add('karusel-nav-elements-stop-anim');
                                lastElDelete.remove();
                                            
                                karuselNavElementsContent.insertAdjacentHTML('afterbegin', lastElDeleteHtml);
                    
                                let vewTop = karuselNavElementsContent.offsetTop - Math.ceil((karuselNavElements.clientHeight / 3));
                            
                                karuselNavElementsContent.style.top = vewTop + 'px';
                    
                                setTimeout(() => {
                                    karuselNavElements.classList.remove('karusel-nav-elements-stop-anim');
                                    let active = karuselNavElementsContent.querySelector('.active');
                                    active.previousElementSibling.classList.add('active');
                                    active.classList.remove('active');

                                    //меняем картинку
                                    let elementDataImgKarusel = document.querySelector('.karusel-nav-elements-content .active').getAttribute('data-img-karusel');

                                    let sectKaruselOknoImgSrc = document.querySelector('.sect-karusel-okno-img img').src;
                                    let sectKaruselOknoImg = document.querySelector('.sect-karusel-okno-img img');
                            
                                
                                    if(elementDataImgKarusel != sectKaruselOknoImgSrc){
                                        sectKaruselOknoImg.src=elementDataImgKarusel; 
                                    }
                                    //меняем картинку конец
                                
                                    karuselNavNextTrue = true;
                                }, 15);
                            
                            }, 311);
                        
                
                
                        
                        }
                    
                    });
                
                
                
                
                
                
                    let karuselNavPrev = document.querySelector('.karusel-nav-prev');
                    //let karuselNavNextTrue = true;
                    karuselNavPrev.addEventListener('click', ()=>{
                        if(karuselNavNextTrue == true){
                            karuselNavNextTrue = false;
                            let karuselNavElements = document.querySelector('.karusel-nav-elements');
                            let karuselNavElementsContent = document.querySelector('.karusel-nav-elements-content');
                    
                            let firstElDelete = document.querySelector('.karusel-nav-elements .element');
                            let firstElDeleteHtml = firstElDelete.outerHTML;
                        
                            //    let lastElDelete = document.querySelector('.karusel-nav-elements .element:last-child');
                            //     console.log(lastElDelete);
                            
                            // console.log(karuselNavElementsContent.offsetTop);
                            // console.log(karuselNavElements.clientHeight);
                            let vewTop = karuselNavElementsContent.offsetTop - Math.ceil((karuselNavElements.clientHeight / 3));
                            karuselNavElementsContent.style.top = vewTop + 'px';
                            //console.log(Math.ceil((karuselNavElements.clientHeight / 3)));
                            setTimeout(()=>{
                                
                                karuselNavElements.classList.add('karusel-nav-elements-stop-anim');
                                firstElDelete.remove();
                                            
                                karuselNavElementsContent.insertAdjacentHTML('beforeend', firstElDeleteHtml);
                    
                                let vewTop = karuselNavElementsContent.offsetTop + Math.ceil((karuselNavElements.clientHeight / 3));
                                //console.log(Math.ceil((karuselNavElements.clientHeight / 3)));
                                karuselNavElementsContent.style.top = vewTop + 'px';
                    
                                setTimeout(() => {
                                    karuselNavElements.classList.remove('karusel-nav-elements-stop-anim');
                
                
                                    let active = karuselNavElementsContent.querySelector('.active');
                                    active.nextElementSibling.classList.add('active');
                                    active.classList.remove('active');
                
                                    //меняем картинку
                                    let elementDataImgKarusel = document.querySelector('.karusel-nav-elements-content .active').getAttribute('data-img-karusel');

                                    let sectKaruselOknoImgSrc = document.querySelector('.sect-karusel-okno-img img').src;
                                    let sectKaruselOknoImg = document.querySelector('.sect-karusel-okno-img img');
                            
                                
                                    if(elementDataImgKarusel != sectKaruselOknoImgSrc){
                                        sectKaruselOknoImg.src=elementDataImgKarusel; 
                                    }
                                    //меняем картинку конец
                
                                    karuselNavNextTrue = true;
                                }, 15);
                            
                            }, 311);
                        //console.log(vewTop);
                        }
                        
                    })
                
                
                
                
                
                
                
                
                
                    //let karuselNavNextTrue = true;
                    function karuselNavPrevTo(e){
                        // console.log(e.currentTarget);
                        
                        let activElement = e.currentTarget;
                        
                            if(karuselNavNextTrue == true){
                                karuselNavNextTrue = false;
                                let karuselNavElements = document.querySelector('.karusel-nav-elements');
                                let karuselNavElementsContent = document.querySelector('.karusel-nav-elements-content');
                        
                                let firstElDelete = document.querySelector('.karusel-nav-elements .element');
                                let firstElDeleteHtml = firstElDelete.outerHTML;
                
                                let firstElDelete_2 = document.querySelectorAll('.karusel-nav-elements .element')[1];
                                let firstElDeleteHtml_2 = firstElDelete_2.outerHTML;
                            
                            
                                let vewTop = karuselNavElementsContent.offsetTop - Math.ceil((karuselNavElements.clientHeight * (0.6666666666666) ));
                                karuselNavElementsContent.style.top = vewTop + 'px';
                                
                                setTimeout(()=>{
                                    
                                    karuselNavElements.classList.add('karusel-nav-elements-stop-anim');
                                    firstElDelete.remove();
                                    firstElDelete_2.remove();
                                                
                                    karuselNavElementsContent.insertAdjacentHTML('beforeend', firstElDeleteHtml);
                                    karuselNavElementsContent.insertAdjacentHTML('beforeend', firstElDeleteHtml_2);
                        
                                    let vewTop = karuselNavElementsContent.offsetTop + Math.ceil((karuselNavElements.clientHeight * (0.6666666666666) ));
                                
                                    karuselNavElementsContent.style.top = vewTop + 'px';
                        
                                    setTimeout(() => {
                                        karuselNavElements.classList.remove('karusel-nav-elements-stop-anim');
                    
                    
                                        let active = karuselNavElementsContent.querySelector('.active');
                                        
                                        // active.nextElementSibling.classList.add('active');
                                        // e.currentTarget.classList.add('active');
                                        active.classList.remove('active');
                                        activElement.classList.add('active');
                                        
                                        //меняем картинку
                                            let elementDataImgKarusel = document.querySelector('.karusel-nav-elements-content .active').getAttribute('data-img-karusel');

                                            let sectKaruselOknoImgSrc = document.querySelector('.sect-karusel-okno-img img').src;
                                            let sectKaruselOknoImg = document.querySelector('.sect-karusel-okno-img img');
                                    
                                        
                                            if(elementDataImgKarusel != sectKaruselOknoImgSrc){
                                                sectKaruselOknoImg.src=elementDataImgKarusel; 
                                            }
                                        //меняем картинку конец

                                        karuselNavNextTrue = true;
                                    }, 15);
                                
                                }, 311);
                            //console.log(vewTop);
                            }
                            
                        
                    }
                }

                
                






                let karuselNavMobNext = document.querySelector('.karusel-nav-mob-next');
                karuselNavMobNext.addEventListener('click', ()=>{
                   
                    //console.log(activem);

                    let karuselNavMobElements = document.querySelector('.karusel-nav-mob-elements');
                    let karuselNavMobElementsContent = document.querySelector('.karusel-nav-mob-elements-content');

                    // let styleLeftPx = ; //стиль отступ слева
                    let styleRigthPx = Math.round(Number(karuselNavMobElementsContent.style.right.replace(/[^-\d]/g, ''))); //стиль отступ справа
                    console.log('слева:' + Math.round(Number(karuselNavMobElementsContent.style.left.replace(/[^-\d]/g, ''))));
                    console.log('справа: ' + Math.round(Number(karuselNavMobElementsContent.style.left.replace(/[^-\d]/g, ''))));
                    let sizeProkrutki = (Math.round(karuselNavMobElements.clientWidth / 3));

                    if(window.innerWidth < 501){
                        sizeProkrutki = (Math.round(karuselNavMobElements.clientWidth / 2));
                    }
                    else{
                        sizeProkrutki = (Math.round(karuselNavMobElements.clientWidth / 3));
                    }


                    if(Math.round(Number(karuselNavMobElementsContent.style.left.replace(/[^-\d]/g, '')))>=0){
                        console.log(2);
                        let karuselNavMob = document.querySelector('.karusel-nav-mob');
                        karuselNavMob.classList.add('karusel-nav-mob-mon-anim');
                        setTimeout(() => {
                            let dubWidth = karuselNavMobElementsContent.clientWidth;
                            let dublHtml = karuselNavMobElementsContent.innerHTML;
                            const regActivem=/activem/g;
                            dublHtml = dublHtml.replace(regActivem, '');
                           
                                
                                

                            karuselNavMobElementsContent.insertAdjacentHTML('afterbegin', dublHtml);
                            let new_left = (Math.round(Number(karuselNavMobElementsContent.style.left.replace(/[^-\d]/g, ''))) - dubWidth) + 'px';
                            karuselNavMobElementsContent.style.left = new_left;
                            
                            setTimeout(() => {
                                karuselNavMob.classList.remove('karusel-nav-mob-mon-anim');
                                karuselNavMobElementsContent.style.left = (Math.round(Number(karuselNavMobElementsContent.style.left.replace(/[^-\d]/g, ''))) + sizeProkrutki) + 'px';
                                    setTimeout(() => {
                                        let activem = document.querySelector('.karusel-nav-mob .karusel-nav-mob-elements-content .activem');
                                        activem.previousElementSibling.classList.add('activem');
                                        activem.classList.remove('activem');

                                         //меняем картинку
                                        let elementDataImgKarusel = document.querySelector('.karusel-nav-mob .karusel-nav-mob-elements-content .activem').getAttribute('data-img-karusel');

                                        let sectKaruselOknoImgSrc = document.querySelector('.sect-karusel-okno-img img').src;
                                        let sectKaruselOknoImg = document.querySelector('.sect-karusel-okno-img img');
                                
                                    
                                        if(elementDataImgKarusel != sectKaruselOknoImgSrc){
                                            sectKaruselOknoImg.src=elementDataImgKarusel; 
                                        }
                                        //меняем картинку конец

                                    }, 15);
                                
                            }, 15);
                        }, 15);
                            
                    }
                    else{
                        console.log(1);
                        karuselNavMobElementsContent.style.left = (Math.round(Number(karuselNavMobElementsContent.style.left.replace(/[^-\d]/g, ''))) + sizeProkrutki) + 'px';
                        setTimeout(() => {
                            let activem = document.querySelector('.karusel-nav-mob .karusel-nav-mob-elements-content .activem');
                            activem.previousElementSibling.classList.add('activem');
                            activem.classList.remove('activem');

                             //меняем картинку
                            let elementDataImgKarusel = document.querySelector('.karusel-nav-mob .karusel-nav-mob-elements-content .activem').getAttribute('data-img-karusel');

                            let sectKaruselOknoImgSrc = document.querySelector('.sect-karusel-okno-img img').src;
                            let sectKaruselOknoImg = document.querySelector('.sect-karusel-okno-img img');
                    
                        
                            if(elementDataImgKarusel != sectKaruselOknoImgSrc){
                                sectKaruselOknoImg.src=elementDataImgKarusel; 
                            }
                            //меняем картинку конец
                        }, 15);
                    }

                   
                   
                  
                });

                
                let karuselNavMobPrev = document.querySelector('.karusel-nav-mob-prev');
                karuselNavMobPrev.addEventListener('click', ()=>{
                     let activem = document.querySelector('.karusel-nav-mob .karusel-nav-mob-elements-content .activem');
                    
                    let karuselNavMobElements = document.querySelector('.karusel-nav-mob-elements');
                    let karuselNavMobElementsContent = document.querySelector('.karusel-nav-mob-elements-content');
                    let sizeProkrutki = Math.round((karuselNavMobElements.clientWidth / 3)); //прокрутка на это число ++++
                    if(window.innerWidth < 501){
                        sizeProkrutki = (Math.round(karuselNavMobElements.clientWidth / 2));
                    }
                    else{
                        sizeProkrutki = (Math.round(karuselNavMobElements.clientWidth / 3));
                    }
                    let styleLeftPx = Math.round(Number(karuselNavMobElementsContent.style.left.replace(/[^-\d]/g, ''))); //стиль отступ слева
                    let styleRigthPx = Math.round(Number(karuselNavMobElementsContent.style.right.replace(/[^-\d]/g, ''))); //стиль отступ справа

                    console.log('слева: ' + styleLeftPx);
                    console.log('справа: ' + styleRigthPx);
                    
                    if(Math.round(Number(karuselNavMobElementsContent.style.left.replace(/[^-\d]/g, ''))) < 0){
                        //console.log('1111');
                        //console.log(karuselNavMobElementsContent.clientWidth);
                        //console.log(((Math.round(Math.abs(Number(karuselNavMobElementsContent.style.left.replace(/[^-\d]/g, '')) )) + Math.round(karuselNavMobElements.clientWidth)) + 0) );
                        if(Math.round(karuselNavMobElementsContent.clientWidth) <= ((Math.round(Math.abs(Number(karuselNavMobElementsContent.style.left.replace(/[^-\d]/g, '')) )) + Math.round(karuselNavMobElements.clientWidth)) + sizeProkrutki) ){
                            //console.log('11112');
                            let karuselNavMob = document.querySelector('.karusel-nav-mob');
                            karuselNavMob.classList.add('karusel-nav-mob-mon-anim');
                            setTimeout(() => {
                                let dubWidth = karuselNavMobElementsContent.clientWidth;
                              

                                let dublHtml = karuselNavMobElementsContent.innerHTML;
                                 const regActivem=/activem/g;
                                 dublHtml = dublHtml.replace(regActivem, '');
            
                                karuselNavMobElementsContent.insertAdjacentHTML('beforeend', dublHtml);
                                
                                setTimeout(() => {
                                    karuselNavMob.classList.remove('karusel-nav-mob-mon-anim');
                                    karuselNavMobElementsContent.style.left = Math.round((styleLeftPx - sizeProkrutki)) + 'px';

                                    activem.nextElementSibling.classList.add('activem');
                                    activem.classList.remove('activem');

                                    //меняем картинку
                                        let elementDataImgKarusel = document.querySelector('.karusel-nav-mob .karusel-nav-mob-elements-content .activem').getAttribute('data-img-karusel');

                                        let sectKaruselOknoImgSrc = document.querySelector('.sect-karusel-okno-img img').src;
                                        let sectKaruselOknoImg = document.querySelector('.sect-karusel-okno-img img');
                                
                                    
                                        if(elementDataImgKarusel != sectKaruselOknoImgSrc){
                                            sectKaruselOknoImg.src=elementDataImgKarusel; 
                                        }
                                    //меняем картинку конец


                                }, 15);

                            }, 15);
                        
                        }
                        else{
                            karuselNavMobElementsContent.style.left = Math.round((styleLeftPx - sizeProkrutki)) + 'px';

                            activem.nextElementSibling.classList.add('activem');
                            activem.classList.remove('activem');

                            //меняем картинку
                                let elementDataImgKarusel = document.querySelector('.karusel-nav-mob .karusel-nav-mob-elements-content .activem').getAttribute('data-img-karusel');

                                let sectKaruselOknoImgSrc = document.querySelector('.sect-karusel-okno-img img').src;
                                let sectKaruselOknoImg = document.querySelector('.sect-karusel-okno-img img');
                        
                            
                                if(elementDataImgKarusel != sectKaruselOknoImgSrc){
                                    sectKaruselOknoImg.src=elementDataImgKarusel; 
                                }
                            //меняем картинку конец
                        }
                    }
                    else{
                        karuselNavMobElementsContent.style.left = Math.round((styleLeftPx - sizeProkrutki)) + 'px';

                        activem.nextElementSibling.classList.add('activem');
                     activem.classList.remove('activem');

                     //меняем картинку
                        let elementDataImgKarusel = document.querySelector('.karusel-nav-mob .karusel-nav-mob-elements-content .activem').getAttribute('data-img-karusel');

                        let sectKaruselOknoImgSrc = document.querySelector('.sect-karusel-okno-img img').src;
                        let sectKaruselOknoImg = document.querySelector('.sect-karusel-okno-img img');
                
                    
                        if(elementDataImgKarusel != sectKaruselOknoImgSrc){
                            sectKaruselOknoImg.src=elementDataImgKarusel; 
                        }
                    //меняем картинку конец
                    }
                    


                    
                    
                     
                    
                })
            



                setInterval(() => {
                    let elements = document.querySelectorAll('.karusel-nav-mob-elements-content .element');
                    let karuselNavMobElements = Math.round(document.querySelector('.karusel-nav-mob-elements').clientWidth);
                    if(window.innerWidth < 501){
                        karuselNavMobElements = Math.round((karuselNavMobElements / 2));
                    }
                    else{
                        karuselNavMobElements = Math.round((karuselNavMobElements / 3));
                    }
                
                    
                    elements.forEach(element => {
                        element.style.width= karuselNavMobElements + 'px';
                    });
                    
                }, 70);
                

                // для всплывашки, не относится к моей разработке
                let sectKaruselOknoImgFanVspl = document.querySelector('.sect-karusel-okno-img');
                sectKaruselOknoImgFanVspl.addEventListener('click', sectKaruselOknoImgFanVspl_f);
                function sectKaruselOknoImgFanVspl_f(){
                    let sectKaruselOknoImgFanVsplSrc = sectKaruselOknoImgFanVspl.querySelector('img').src;
                    //console.log(sectKaruselOknoImgFanVsplSrc);
                    let karuselNavMobElementsContentFan = document.querySelector('.karusel-nav-mob-elements-content');
                    let strokaPoiska = 'div[data-src="' + sectKaruselOknoImgFanVsplSrc + '"]';
                    //console.log(strokaPoiska);
                    let iscEl = karuselNavMobElementsContentFan.querySelector(strokaPoiska);
                    iscEl.click();
                    //console.log(iscEl);
                }

            }
}
