import 'nodelist-foreach-polyfill';

import * as umi from '@umi-top/umi-core-js'
 
import { Fancybox } from "@fancyapps/ui";
Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });


  import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
  import 'axios';
  
  // You will need a ResizeObserver polyfill for browsers that don't support it! (iOS Safari, Edge, ...)
  import ResizeObserver from 'resize-observer-polyfill';
  window.ResizeObserver = ResizeObserver;


import * as dataTopSlider from './modules/top-slider'
dataTopSlider.topSlider();

import * as dataMobMenuTop from './modules/menu-top'
dataMobMenuTop.mobMenuTop();

import * as datakaruselNavNext from './modules/karusel'
datakaruselNavNext.karuselNavNext();


import * as datatabs from './modules/tabs'
datatabs.tabs();

import * as datamap from './modules/map'
datamap.map();

import * as dataforms from './modules/forms'
dataforms.forms();


import * as dataup from './modules/up'
dataup.up();