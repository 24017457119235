export function mobMenuTop(){
    let mobMenuTop = document.querySelector('.mob-menu-js');
    let blokMenuMobile = document.querySelector('.blok-menu-mobile');
    if(mobMenuTop && blokMenuMobile){
        mobMenuTop.addEventListener('click', ()=>{
            mobMenuTop.classList.toggle('active');
            blokMenuMobile.classList.toggle('active');
        })
    }
    

    let vstMenuGorogovClose = document.querySelector('.vstMenuGorogovClose');
    if(vstMenuGorogovClose){
        vstMenuGorogovClose.addEventListener('click', vstMenuGorogovClosetun);
    }
 

    let openMenuGoroda = document.querySelector('.openMenuGoroda');
    if(openMenuGoroda){
        openMenuGoroda.addEventListener('click', vstMenuGorogovClosetun);
    }
   
    
     
        function vstMenuGorogovClosetun(){
            let vstMenuGorogov = document.querySelector('.vstMenuGorogov');
            if(vstMenuGorogov){
                vstMenuGorogov.classList.toggle('vstMenuGorogovDinon');
            } 
        }
    
   
    
}
