export function topSlider(){

    let topSliders = document.querySelectorAll('.top-slider'); //находим все слайдеры на странице
    if(topSliders){
        topSliders.forEach(element => {
            let topSlidersElements = element.querySelectorAll('.top-slider-iter'); //находим все слайды у текущего слайда 
            let next = element.querySelector('.top-slider-next'); //находим кнопку вперед
            let prev = element.querySelector('.top-slider-prev'); //находим кнопку назад
            let fon = element.querySelector('.fon'); //находим фон,забеляет слайды при переключении
            next.addEventListener('click', function(){ 
                let active = element.querySelector('.active'); //находим текущий активный элемент
                fon.classList.add('fonActive'); //начинаем забеление фона
                setTimeout(()=>{
                    let activeNext = active.nextElementSibling; //находим следующий за активным элементом элемент
                    
                    if((activeNext == next ) || (activeNext == null) || (activeNext == fon)){ //если дальше нет слайда, то берем первый
                        topSlidersElements[0].classList.add('active');
                    }
                    else{//иначе идем дальше
                        activeNext.classList.add('active');
                    }
                    active.classList.remove('active'); //в любом случае убираем предыдущий активный элемент
                    fon.classList.remove('fonActive'); //возвразаем фон
                }, 200);
            })
    
            prev.addEventListener('click', function(){
                let active = element.querySelector('.active');
                fon.classList.add('fonActive');
                setTimeout(()=>{
                    let activePrev = active.previousElementSibling;
                    console.log(activePrev);
                    if((activePrev == prev ) || (activePrev == null) || (activePrev == fon)){
                        topSlidersElements[(topSlidersElements.length - 1)].classList.add('active');
                    }
                    else{
                        activePrev.classList.add('active');
                    }
                    active.classList.remove('active');
                    fon.classList.remove('fonActive');
                }, 200);
            })
    
            setInterval(()=>{//автозапуск слайдера
                prev.click();
            }, 6000)
    
        });
    }
    

}