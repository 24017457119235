export function up(){
    setTimeout(() => {
        let upTop = document.querySelector('.up-top');
        if(upTop){
           
            let body_obv_scrttt = document.getElementsByClassName('simplebar-content-wrapper')[0];

            upTop.addEventListener('click', upTop_top_f);
            function upTop_top_f(){
                body_obv_scrttt.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
            
            if(body_obv_scrttt){
                body_obv_scrttt.addEventListener('scroll', body_obv_scrttt_f);
            }
           
            function body_obv_scrttt_f(){
                let body_obv_scrttt = document.getElementsByClassName('simplebar-content-wrapper')[0];
                if(body_obv_scrttt){
                    if(body_obv_scrttt.scrollTop>500){
                        upTop.classList.remove('up-top-none');
                    }
                    else{
                        upTop.classList.add('up-top-none');
                    }
                }
               
            }
        }
         
    }, 4000);
    


}